<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">弹幕管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                clearable
                placeholder="请输入班级名称"
                size="small"
              ></el-input>
            </div>
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
                <el-input
                v-model="courseName"
                clearable
                placeholder="请输入课程名称"
                size="small"
              ></el-input>
            </div>
            <div title="弹幕内容" class="searchboxItem ci-full">
              <span class="itemLabel">弹幕内容:</span>
               <el-input
                v-model="sourceContent"
                clearable
                placeholder="请输入弹幕内容"
                size="small"
              ></el-input>
            </div>
            <div title="状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:3rem">状态:</span>
               <el-select v-model="deleted" placeholder="请选择" clearable size="small">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
            </div>

            <div class="df" style="padding:0 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="弹幕内容"
                align="left"
                show-overflow-tooltip
                prop="sourceContent"
                min-width="200px"
              />
              <el-table-column
                label="课件名称"
                align="left"
                show-overflow-tooltip
                prop="kpointName"
                min-width="200"
              />
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                prop="courseName"
                min-width="200"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                min-width="200"
              />
              <el-table-column
                  label="发送人"
                  align="left"
                  show-overflow-tooltip
                  prop="userName"
                  min-width="100"
              />
              <el-table-column
                label="发送时间"
                align="left"
                show-overflow-tooltip
                prop="commitTime"
                min-width="150px"
              >
                <template slot-scope="scope">
                  {{ scope.row.commitTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="150px"
                fixed="right"
              >
                <div slot-scope="scope">
                   <el-switch
                    class="selStyle"
                    v-model="scope.row.deleted"
                    :disabled="scope.row.deleted"
                    :active-value="false"
                    :inactive-value="true"
                    @change="editModel(scope.row.barrageId)"
                  ></el-switch>
                  <span>{{ scope.row.deleted == false ? "正常" : "已关闭" }}</span>
                  <!-- <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    :disabled="scope.row.deleted"
                    @click="editModel(scope.row.barrageId)"
                    >关闭</el-button
                  > -->
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluate/contractManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      userName: "", //姓名
      sourceContent: "", //内容
      courseName: "", //课程名称
      projectName: '', //班级名称
      commentContent: '', //评价内容
      DialogVisible: false,
      deleted:'',
      ruleForm:{},
      taskId: [],
      options:[
        {
          value:true,
          label:'已关闭'
        },
        {
          value:false,
          label:'正常'
        }
      ]
    };
  },
  watch: {},
  created() {},
 computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        deleted:this.deleted
      };
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.sourceContent) {
        params.sourceContent = this.sourceContent;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      this.doFetch({
        url: "/edu/project/course/barrage/pageList",
        params,
        pageNum,
      });
    },
    // 关闭
    editModel(barrageId) {
      let params = {barrageId: barrageId}
      this.$post('/edu/project/course/barrage/close',params).then(ret => {
        this.$message({
          message:'操作成功',
          type:'success'
        })
        // this.getData(-1);
      }).catch(err => {
        return
      })
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope>
.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.Score-ruleForm {
    .el-form-item {
    display: flex;
    align-items: center;
}
}

</style>
